.chat_header {
  width: 100%;
  z-index: 9;
  background: white;
}

.chat_header > div {
  position: relative;
}

.chat_header * {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.chat_header .chat_lock {
  position: absolute;
  right: 25px;
  cursor: pointer;
}

.chat_lvl_1_header_title {
  height: 60px;
  border-bottom: solid 1px #d5dee1;
}

.chat_lvl_1_header_title img {
  vertical-align: middle;
  height: 45px;
  border-radius: 50%;
  margin-right: 15px;
}

.chat_lvl_1_header_title h3 {
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  line-height: 64.5px;
  color: #181a55;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: calc(100% - 50px);
  margin: 0 auto;
}

.chat_lvl_2_header_title {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px #d5dee1;
  height: 56px;
}

.chat-thread_lvl_2_header_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 25px;
}

.chat-thread_lvl_2_header_wrapper h4 {
  color: #272957;
  font-size: 20px;
}

.chat-thread_lvl_2_header_wrapper .chat-thread_close-btn {
  font-size: 23px;
  color: rgb(87, 87, 87);
  padding: 2px;
  cursor: pointer;
}

.chat_header_btn_right {
  border: none;
}

.chat_header_btn_right:hover {
  cursor: pointer;
}

.chat_header_btn_right:focus {
  outline: none;
}

.chat_header_btn_right {
  height: 30px;
  width: 110px;
  border-radius: 15px;
  background-color: #e52730;
  border: none;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.31px;
  color: #fff;
  margin-left: 20px;
}

.chat_powered_by {
  display: inline-block;
}

.chat_powered_by_intro {
  max-height: 17px;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.86px;
  color: #8b8ca3;
  display: inline-block;
  vertical-align: middle;
}

.chat_powered_by_logo {
  display: inline-block;
  max-height: 50px;
  vertical-align: middle;
  margin-left: 20px;
}

.chat_powered_by_logo img {
  max-height: 50px;
  width: 100%;
}

.sidebar-collapse-open{
  float: left;
}
/* DROP DOWN */


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .chat_lvl_1_header_title {
    padding: 0 15px;
  }

  .chat_lvl_1_header_title h3 {
    font-size: 24px;
  }

  .chat_powered_by {
    display: block;
    text-align: center;
  }

  .chat_powered_by_intro {
    font-size: 10px;
  }

  .chat_powered_by_logo {
    margin: 3px 0 0 0;
  }

  .chat_powered_by_logo img {
    max-height: 30px;
  }

  .sidebar-collapse-open {
    display: block;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .sidebar-collapse-open {
    display: block;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .sidebar-collapse-open {
    display: none;
  }
}
