body {
  overflow: hidden;
}

.rce-sidebar {
  width: 300px;
  height: 100vh;
  position: fixed;
  background-color: #292929;
}

.rce-sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.rce-sidebar li {
  height: 30px;
  line-height: 30px;
  color: #000;
  background-color: #F5F1ED;
  padding-left: 15px;
  margin: 1px;
}


.container-chat {
  width: 100%;
  display: flex;
  flex-flow: column;
  position: relative;
}

.container-chat.thread-chat {
  max-width: 450px;
  border-left: 1px solid #d5dee1;
}

.container-chat.thread-chat .thread-chat_replies {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  font-size: 14px;
  color: #929292;
}

.container-chat.thread-chat .thread-chat_replies span {
  white-space: nowrap;
  margin-right: 15px;
}

.container-chat.thread-chat .thread-chat_replies::after {
  content: '';
  height: 1px;
  width: 100%;
  background: #d5dee1;
}

.container-chat.thread-chat .rce-mbox {
  background: transparent !important;
  height: auto;
}

.pinned-message-wrapper {
  position: relative;
  background: #4246a2;
  cursor: pointer;
}

.pinned-message-wrapper .rce-container-mbox {
  padding-top: 20px;
}

.pinned-message-wrapper .rce-container-mbox .rce-mbox {
  background: transparent !important;
}

.pinned-message-wrapper .rce-mbox-title,
.pinned-message-wrapper .rce-mbox-text,
.pinned-message-wrapper .rce-mbox-time {
  color: #fff;
}

.pinned-message-wrapper .pinned-message-info {
  display: flex;
  align-items: center;
  position: absolute;
  left: 15px;
  top: 10px;
  font-size: 10px;
  font-weight: 500;
  color: #fff;
}

.pinned-message-wrapper .pinned-message-info svg {
  margin-right: 3.5px;
  fill: rgb(55, 170, 55);
}

.rce-all-messages-loaded {
  flex: 0 1 auto;
  margin: 0;
  padding: 10px 0;
  color: #fff;
  background: #515151;
  text-align: center;
  z-index: 99999;
}

.rce-container-mlist {
  flex: 1 1 auto;
  overflow: hidden;
  padding-bottom: 25px;
  box-sizing: border-box;
}

.rce-mlist {
  scroll-behavior: smooth;
}

.rce-container-mbox,
.rce-mbox {
  position: relative;
}

.rce-mbox-title > span {
  cursor: pointer;
}

.rce-container-mbox {
  /* min-height: 140px; */
}

.rce-mbox {
  background: transparent;
  height: 90px;
}

.rce-container-mbox:hover .message-actions,
.message-actions-pinned {
  opacity: 1 !important;
}

.message-actions-pinned {
  top: auto !important;
  right: 10px !important;
  bottom: 10px;
}

.message-actions-pinned .message-actions-action {
  width: auto !important;
  font-size: 14px;
}

.rce-mbox .message-actions,
.rce-container-mbox .message-actions {
  position: absolute;
  display: flex;
  top: 2px;
  right: 160px;
  border: 1px solid #d4d4d4;
  background: #fff;
  padding: 2.5px;
  border-radius: 5px;
  opacity: 0;
  z-index: 1;
  transition: opacity .25s ease-in-out;
}

.rce-mbox .message-actions-action,
.rce-container-mbox .message-actions-action {
  position: relative;
  padding: 5px;
  color: gray;
  cursor: pointer;
  border-radius: 3px;
  text-align: center;
  width: 30px;
}

.rce-mbox .message-actions-action:hover,
.rce-container-mbox .message-actions-action:hover {
  background: #dfdfdf;
  color: rgb(49, 49, 49);
}

.rce-container-mbox .message-actions-action .message-actions-tooltip {
  display: table;
  position: absolute;
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  width: auto;
  opacity: 0;
  white-space: nowrap;
  background: rgb(26, 26, 26);
  color: #fff;
  padding: 5px 10px;
  font-size: 10px;
  font-weight: 700;
  border-radius: 5px;
  transition: opacity .25s ease-in-out;
  pointer-events: none;
}

.pinned-message-wrapper .message-actions-action .message-actions-tooltip {
  top: -30px;
}

.rce-container-mbox .message-actions-action:hover .message-actions-tooltip {
  opacity: 1;
}

.rce-mbox .message-thread-replies,
.rce-container-mbox .message-thread-replies,
.rce-mbox .message-likes,
.rce-container-mbox .message-likes {
  display: flex !important;
  align-items: center;
  position: absolute;
  left: 73px;
  bottom: 10px;
  display: inline-block;
  background: #fff;
  color: #575757;
  font-size: 13px;
  border: 1px solid #d4d4d4;
  padding: 7px 15px;
  line-height: 100%;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1;
  transition: background .25s ease-in-out;
  user-select: none;
}

.rce-mbox .message-thread-replies:hover,
.rce-container-mbox .message-thread-replies:hover,
.rce-mbox .message-likes:hover,
.rce-container-mbox .message-likes:hover {
  background: #f5f5f5;
}

.rce-mbox.message-has-likes .message-thread-replies,
.rce-container-mbox.message-has-likes .message-thread-replies {
  left: 150px !important;
}

.rce-container-mbox .message-thread-replies span {
  color: #4145a3;
  font-weight: bold;
  margin-right: 6px;
}

.rce-container-mbox .message-thread-replies svg {
  font-size: 15px;
  margin-left: 6px;
}

.rce-container-input {
  border-top: 1px solid black;
}

.rce-container-reply {
  flex: 0 1 auto;
  position: relative;
  background: #cccfd1;
  box-sizing: border-box;
  padding: 10px 50px 10px 10px;
  margin: 0 15px;
}

.rce-container-reply .rce-mbox-reply-message {
  max-width: 700px;
}

.rce-container-mbox .rce-mbox-reply,
.rce-container-reply .rce-mbox-reply {
  margin: 0;
  /* max-width: 100% !important; */
  display: inline-flex !important;
  /* flex-direction: row; */
  align-items: center;
  padding: 5px;
  margin-bottom: 5px;
}

.rce-container-reply .rce-mbox-reply.rce-mbox-reply-border {
  min-width: 50%;
}

.rce-mbox-reply .rce-mbox-reply-left,
.rce-container-reply .rce-mbox-reply-left {
  order: 1;
  overflow: hidden;
}

.rce-mbox-reply .rce-mbox-reply-right,
.rce-container-reply .rce-mbox-reply-right {
  order: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
}

.rce-mbox-reply .rce-mbox-reply-right img,
.rce-container-reply .rce-mbox-reply-right img {
  width: 28px;
  height: 28px;
  min-width: 28px;
  min-height: 28px;
  border-radius: 50%;
}

.rce-container-reply .rce-reply-cancel {
  position: absolute;
  top: 25%;
  right: 10px;
  border: none;
  background: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-weight: bold;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}

.rce-load-more {
  flex: 0 1 auto;
  margin: 0;
  padding: 10px 0;
  color: #fff;
  background: #555;
  text-align: center;
  z-index: 99999;
  background-color: #e62630;
  border: none;
  cursor: pointer;
}

textarea {
  height: 40px;
  border-top: 2px solid black;
}

/* Mention Input */
.mention-input.mention-input--multiLine {
  flex: 0;
  margin: 0 15px;
  height: 52px;
}

.mention-input__control {
  position: absolute;
  width: 100%;
}

textarea.mention-input__input {
  border: 2px solid #d5dee1;
  outline: 0;
  position: relative !important;
  height: 52px !important;
  line-height: 1;
  overflow: auto !important;
}

.emoj-mvm {
  float: left;
  position: relative;
  left: 35px;
  top: 15px;
  cursor: pointer;
  z-index: 99999;
}

.emoj-record {
  float: right;
  position: relative;
  right: 35px;
  top: 15px;
  cursor: pointer;
}

.emoj-add {
  float: right;
  position: relative;
  right: 70px;
  top: 15px;
  cursor: pointer;
}

.mention-input__highlighter {
  word-break: break-all;
  position: absolute !important;
  top: 50% !important;
  z-index: -1 !important;
}

.mention-input__suggestions {
  left: 0 !important;
  top: 0 !important;
  transform: translateY(calc(-100% - 12px));
  border: 2px solid #d5dee1;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom: none;
  padding-top: 15px !important;
}

.mention-input__suggestions__list {
  max-height: 50vh;
  overflow: auto;
}

.mention-input__suggestions ul li {
  padding: 2.5px 20px !important;
  font-size: 14px;
}

.mention-input__suggestions ul li:hover {
  background: #b3c4dc52;
}

.text-message-options {
  text-align: center;
}

.text-message-options {
  font-size: 12px;

}

.text-message-options svg {
  margin: 10px 10px;
  color: #b7b7b7;
}

.text-message-options svg:hover {
  cursor: pointer;
}

@media screen and (max-width: 1600px) {
  .thread-chat {
    position: absolute;
    top: 0;
    left: 380px;
    right: 0;
    bottom: 0;
    z-index: 99999;
    background: #fff;
    max-width: calc(100% - 380px) !important;
  }
}

@media only screen and (max-width: 992px) {
  .thread-chat {
    left: 0 !important;
    max-width: 100% !important;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .emoj-mvm {
    display: none;
  }

  textarea.mention-input__input {
    padding: 15px 50px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .emoj-mvm {
    display: none;
  }

  textarea.mention-input__input {
    padding: 15px 50px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .emoj-mvm {
    display: block;
  }

  textarea.mention-input__input {
    padding: 15px 50px;
  }
}

@media (hover: none) and (pointer: coarse) {
    .rce-container-mbox .rce-mbox-forward-right {
        opacity: 1;
        visibility: visible;
    }
}

.rce-container-mbox.message-has-likes .rce-mbox,
.rce-container-mbox.message-has-replies .rce-mbox {
  padding-bottom: 45px;
}

.rce-mbox {
  box-shadow: none;
  border: none;
  border-radius: 0;
  margin: 0;
  padding: 10px 15px 15px 15px;
  min-height: 50px;
  max-width: 100%;
  width: 100%;
  height: auto !important;
  border-radius: 0% !important;
  transition: background .25s ease-in-out;
}

.rce-mbox:hover {
  background: #f7f7f7;
}

.rce-mbox:not(.message-focus) .rce-mbox-forward-right {
  background: #f0f0f0;
}

.rce-mbox.message-focus .rce-mbox-forward-right {
  top: -70px;
}

.rce-mbox.rce-mbox-right {
    box-shadow: none;
    border: none;
    border-radius: 0;
    margin: 6px 0;
}

.rce-avatar-container.default{
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.rce-avatar {
  object-fit: cover;
  border-radius: 50%;
}

.rce-mbox-body {
  padding-left: 55px;
}

.rce-mbox-title {
  color: #333;
  font-size: 15px;
  font-weight: 700;
  text-decoration: none;
  margin: 0 0 -25px -55px;
  align-items: initial;
  cursor: initial;
}

.rce-mbox-text {
  font-weight: 400;
  /* max-width: 75%; */
  max-width: calc(100% - 50px);
  font-size: 14px;
  display: block;
  margin-left: 5px;
}

.rce-mbox-text::after {
  display: none;
}

.pinned-message-wrapper .rce-container-mbox .rce-mbox-text {
  max-width: calc(100% - 200px);
}

.rce-mbox-reply.rce-mbox-reply-border {
  display: inline-block;
  border-color: rgb(89 89 89) !important;
  margin-left: 5px;
  border-radius: 0;
  min-width: 100px;
  max-width: 700px;
  border-top-right-radius: 5px;
}

@media screen and (max-width: 1600px) {
  .rce-mbox-reply.rce-mbox-reply-border,
  .rce-container-reply .rce-mbox-reply-message {
    max-width: 500px;
  }
}

@media screen and (max-width: 1400px) {
  .rce-mbox-reply.rce-mbox-reply-border,
  .rce-container-reply .rce-mbox-reply-message {
    max-width: 370px;
  }
}

@media screen and (max-width: 1200px) {
  .rce-mbox-reply.rce-mbox-reply-border,
  .rce-container-reply .rce-mbox-reply-message {
    max-width: 300px;
  }
}

@media screen and (max-width: 1000px) {
  .rce-mbox-reply.rce-mbox-reply-border,
  .rce-container-reply .rce-mbox-reply-message {
    max-width: 200px;
  }
}

.rce-mbox-reply-left {
  padding: 3px 13px 3px 3px;
}

.rce-mbox-reply-owner {
    color: #333 !important;
    font-weight: 500;
    font-size: 15px;
    margin: 0;
    font-style: italic;
}

.rce-mbox-reply-message {
  font-style: italic;
  font-size: 14px;
  max-width: 100%;
  padding-right: 2px;
}

.rce-mbox-forward-right {
    right: 160px;
    top: -20px;
    box-shadow: none;
    border: none;
    z-index: 9999;
}

.rce-mbox-time {
  top: 0px;
  right: 0px;
  text-align: right;
  line-height: 1;
}

@keyframes message-box-default-focus {
  from { background-color: #fff }
  to { background-color: rgb(249 216 214 / 0.5) }
}

.message-focus {
  /* animation-fill-mode: forwards; */
  /* background: #fcfcfc; */
  animation-iteration-count: 1 !important;
  animation-duration: 1.5s !important;
  animation-timing-function: ease-in-out;
  height: auto;
}

.rce-mbox-left-notch,
.rce-mbox-right-notch {
  display: none !important;
}

.react-confirm-alert-overlay {
  z-index: 99999;
}

.react-confirm-alert-body {
  position: relative;
  overflow: auto;
  max-height: calc(100vh - 125px);
}

.react-confirm-alert-close {
  margin-top: 10px;
  margin-bottom: -20px;
  color: #000 !important;
  background-color: transparent !important;
}

.react-confirm-alert h1 {
  font-size: 20px;
}

.react-confirm-alert h2 {
  text-align: center;
  margin-top: 0;
}

.react-confirm-alert input,
.react-confirm-alert select {
  display: block;
  width: 100%;
  height: 35px;
  padding: 0 10px;
  margin-top: 5px;
  margin-bottom: 20px;
  border: 1px solid #E7EAF3;
  border-radius: 5px;
  box-sizing: border-box;
}

.react-confirm-alert .inline-inputs select,
.react-confirm-alert .inline-inputs input {
  display: inline-block;
}

.react-confirm-alert .inline-inputs select {
  width: 100px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.react-confirm-alert .inline-inputs input {
  width: calc(100% - 100px);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.react-confirm-alert button {
  width: 100%;
  height: 40px;
  border: none;
  color: #fff;
  background: #e52730;
  border-radius: 5px;
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
  text-transform: uppercase;
}

/* spinner */
.spinner {
  z-index: 99999;
  margin-top: 15px;
  display: flex;
  justify-content: center;
}
.spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  margin: 8px;
  border: 8px solid #181a55;
  border-radius: 50%;
  animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #181a55 transparent transparent transparent;
}
.spinner div:nth-child(1) {
  animation-delay: -0.45s;
}
.spinner div:nth-child(2) {
  animation-delay: -0.3s;
}
.spinner div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.slow-mode {
  position: relative;
  font-size: 22px;
  top: -38px;
  right: 33px;
  float: right;
}

.slow-mode .slow-mode-tooltip {
  display: table;
  position: absolute;
  right: -10px;
  bottom: calc(100% + 7px);
  z-index: 10;
  width: auto;
  opacity: 0;
  white-space: nowrap;
  background: rgb(26, 26, 26);
  color: #fff;
  padding: 7px 10px;
  font-size: 14px;
  border-radius: 5px;
  transition: opacity .25s ease-in-out;
  pointer-events: none;
}

.slow-mode:hover .slow-mode-tooltip,
.slow-mode-tooltip.shake {
  opacity: 1;
}

.slow-mode-tooltip.shake {
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
