#sidebar {
  min-width: 380px;
  max-width: 380px;
  height: 100vh;
  color: #fff;
  transition: all 0.3s;
  border-right: solid 1px #d5dee1;
  overflow-y: auto;
  background: white;
}

#sidebar ul.components {
  margin: 0;
  padding: 0;
  border-bottom: solid 1px #d5dee1;
  list-style: none;
}

#sidebar .sidebar-header,
#sidebar .sidebar-subheader {
  display: flex;
  align-items: center;
  padding: 0 20px;
}

#sidebar .sidebar-header {
  height: 60px;
  border-bottom: solid 1px #d5dee1;
}

#sidebar .sidebar-header h3 {
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  color: #181a55;
}

#sidebar .sidebar-subheader {
  height: 55px;
}

#sidebar .sidebar-subheader h4 {
  font-size: 16px;
  font-weight: 700;
  color: #181a55;
}

.sidebar-collapse-close {
  float: right;
  font-size: 28px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  #sidebar {
    min-width: calc(100% - 1px);
    max-width: calc(100% - 1px);
  }

  #sidebar.collapse {
    position: absolute;
    z-index: 999;
    left: -100%;
  }

  #sidebar.collapse.active {
    left: 0;
  }

  .sidebar-collapse-close {
    display: block;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  #sidebar.collapse {
    position: absolute;
    z-index: 999;
    left: -100%;
  }

  #sidebar.collapse.active {
    left: 0;
  }

  .sidebar-collapse-close {
    display: block;
  }

  #sidebar {
    min-width: calc(100% - 1px);
    max-width: calc(100% - 1px);
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #sidebar.collapse {
    left: 0;
    position: relative;
  }

  #sidebar {
    min-width: 380px;
    max-width: 380px;
  }

  .sidebar-collapse-close {
    display: none;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}
