.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 2px;
  color: rgba(255, 255, 255, 0.9);
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999999;
  background: #341779;
}

.loading-logo {
  animation: pulse .4s ease 0s infinite alternate;
}

@keyframes pulse {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: .25;
    transform: scale(.75);
  }
}
