#sidebar ul li {
  font-size: 14px;
  font-weight: 500;
  color: #181a55;
  display: flex;
  text-decoration: none;
  position: relative;
  height: 50px;
  user-select: none;
  cursor: pointer;
  padding-left: 20px;
  padding-right: 30px;
  align-items: center;
}

#sidebar ul li::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #d5dee1;
}

#sidebar ul li:not(.active) {
  background-size: 200% 100% !important;;
  background-position: right bottom !important;;
  transition: all 0.33s ease;
}

#sidebar ul li:not(.active):hover {
  cursor: pointer;
  background-position: left bottom !important;
}

#sidebar ul li .color {
  height: 50px;
  width: 10px;
  background: #181a55;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

#sidebar ul li .name {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#sidebar ul li .tag {
  display: inline-block;
  height: 24px;
  line-height: 24px;
  margin-left: auto;
  padding: 0 7px;
  border-radius: 3px;
  background-color: #181a55;
  font-weight: bold;
  font-size: 10px;
  color: #fff;
  text-align: center;
  white-space: nowrap;
}

#sidebar ul li .tag.active {
  background-color: #e52730;
}

#sidebar ul li .dot {
  display: inline-block;
  height: 24px;
  line-height: 24px;
  padding: 0 7px;
  border-radius: 4px;
  background-color: #e52730;
  font-weight: bold;
  font-size: 12px;
  color: #fff;
  text-align: center;
  margin-left: 8px;
}

#sidebar ul li .icon {
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}
