table {
  min-width: 100%;
  margin-bottom: 2em;
  text-align: center;
}

tbody {
  border: 2px solid #d1d1d1;
  border-right: none;
}

tr {
  border-bottom: 1px solid #d1d1d1;
}

td, th {
  padding: .75em;
}

th {
  font-weight: bold;
  font-size: 1.1em;
}

tr:nth-child(odd) {
  background-color: #f2f2f2;
}

td {
  border-right: 2px solid #d1d1d1;
}

ul {
  list-style: none;
}

td li,
td u {
  background-color: rgb(248, 156, 17);
  color: #fff;
  padding: 10px;
  border-radius: 7px;;
  cursor: pointer;
}